import React, { useEffect, useState } from 'react';
import { Button, message, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

const ExpandButton = styled.div`
    position: absolute;
    top: -3px;
    right: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
`;

const StyledCollapse = styled(Collapse)`
    background: #000;
    color: #fff;

    .ant-collapse-item {
        background: #000;
        color: #fff;

        .ant-collapse-header {
            background: #000;
            color: #fff;
            padding: 0px 4px; /* Adjust padding as needed */
        }

        .ant-collapse-content {
            background: #000;
            color: #fff;

            .ant-collapse-content-box {
                background: #000;
                color: #fff;
                padding: 10px; /* Adjust padding as needed */
            }
        }
    }

    .ant-collapse-arrow,
    .ant-collapse-expand-icon {
        display: none;
    }
`;

const LocalizedDate = ({ timestamp, locale, options = {} }) => {
    // Convert timestamp to Date object
    const date = new Date(timestamp * 1000); // assuming the timestamp is in seconds
  
    // Create a DateTimeFormat object with the specified locale and options
    const formattedDate = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...options,
    }).format(date);
  
    return <span>{formattedDate}</span>;
  };

const TrialUpgrade = ({ onClick }) => { 
    const [triggerSubscriptionStatusRefresh, setTriggerSubscriptionStatusRefresh] = useState(false);
    const [subscriptionInfo, setSubscriptionInfo] = useState({});
    const [trialStatus, setTrialStatus] = useState(null);
    const [trialPlan, setTrialPlan] = useState(null);
    const [currentPlanName, setCurrentPlanName] = useState(null);
    const [appSumoPlan, setAppSumoPlan] = useState({});
    const [pageReady, setPageReady] = useState(false);
    const [isYearly, setIsYearly] = useState(false);
    const [expand, setExpand] = useState(false);
    const [trialRemainingDays, setTrialRemainingDays] = useState(0);
    const [pmAttached, setPMAttached] = useState(false);
    const [checkoutInProgress, setCheckoutInProgress] = useState(false);
    const [nextBillingTimestamp, setNextBillingTimestamp] = useState(null);

    const navigate = useNavigate();

    const handlePaymentUpdate = () => {
        setCheckoutInProgress(true);
        let payload = {
            "redirect_path": window.location.pathname
        };
        let url = api_config.BASE_BACKEND_ENDPOINT + 'api/update-billing-method/';
        let apiController = new AbortController();
        const signal = apiController.signal;
    
        UseAPI(signal, url, 'POST', payload)
          .then(function (data) {
            // handle data here
            setCheckoutInProgress(false);
            if (data.code === 200) {
                window.location.href = data.url;          
            }
            else if(data.code === 400) {
              notification.error({
                message: data.title||'',
                description: data.message,
              });
            }
            else if(data.code === 500) {
              notification.error({
                message: data.title||'',
                description: data.message,
              });
            }      
            
          })
          .catch(function (err) {
            setCheckoutInProgress(false);
            notification.error({
              message: 'Request Error',
              description: "Unfortunately, we could not process your request. Please wait a moment and retry. If you still get this error, please contact us at support@unmixr.com",
            });
          });
    
      };

      const handleBillingPortal = () => {
        setCheckoutInProgress(true);
        let payload = {};
        let url = api_config.BASE_BACKEND_ENDPOINT + 'api/customer-portal/';
        let apiController = new AbortController();
        const signal = apiController.signal;
    
        UseAPI(signal, url, 'POST', payload)
          .then(function (data) {
            // handle data here
            setCheckoutInProgress(false);
            if (data.code === 200) {
                window.location.href = data.portal_url;          
            }
            else if(data.code === 400) {
              notification.error({
                message: data.title||'',
                description: data.message,
              });
            }
            else if(data.code === 500) {
              notification.error({
                message: data.title||'',
                description: data.message,
              });
            }      
            
          })
          .catch(function (err) {
            setCheckoutInProgress(false);
            notification.error({
              message: 'Request Error',
              description: "Unfortunately, we could not process your request. Please wait a moment and retry. If you still get this error, please contact us at support@unmixr.com",
            });
          });
    
      };

    useEffect(() => {
        let url = api_config.BASE_BACKEND_ENDPOINT + 'api/subscription-status/';
        let apiController = new AbortController();
        const signal = apiController.signal;
        let payload = {};

        UseAPI(signal, url, 'POST', payload)
        .then(function (data) {
            if (data.code === 200) {
                setSubscriptionInfo(data.plan);
                setTrialStatus(data.trial_status);
                setTrialPlan(data.trial_plan);
                setTrialRemainingDays(data.trial_remaining_days);
                setPMAttached(data.pm_attached);
                setNextBillingTimestamp(data.next_billing_timestamp);
                setCurrentPlanName(data.plan_name);
                setAppSumoPlan(data.appsumo_plan);
                setIsYearly(data.plan && data.plan.billing_cycle === "year");
            } else if (data.code === 401) {
                // window.location.href = "/login";
            } else {
                message.error('Could not fetch subscription detail');
            }
            setPageReady(true);
        })
        .catch(function (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                message.error('Error while fetching subscription detail');
            }
            setPageReady(true);
        });

        return () => {
            apiController.abort();
        };
    }, [triggerSubscriptionStatusRefresh]);

    const toggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <>
        {
            pageReady && trialStatus && trialStatus.toUpperCase() === "TRIALING" &&
             <div className='trial-upgrade' style={{ position: 'fixed', left: '0px', bottom: '0px', width: '230px' }}>
                <StyledCollapse activeKey={expand ? ['1'] : []}>
                        <Panel 
                            header={
                                <div style={{ position: 'relative', padding: '0px 5px 2px 5px' }}>
                                    <div style={{fontWeight: '600', color: '#fff', fontSize: '0.8rem'}}>Your Trial ends {trialRemainingDays===0?'today':`in ${trialRemainingDays} day${trialRemainingDays>1?'s':''}`}</div>
                                    <ExpandButton onClick={toggleExpand}>
                                        {expand ? <DownOutlined />: <UpOutlined />}
                                    </ExpandButton>
                                </div>
                            } 
                            key="1"
                        >
                            <div>
                                {
                                    !pmAttached && <Button 
                                            type='warning' 
                                            style={{ width: '100%', textTransform: 'capitalize', marginTop: '6px' }} 
                                            onClick={() => { handlePaymentUpdate(); }}
                                        >
                                            Update Payment Method
                                    </Button>
                                }                            
                                <p style={{marginTop: '5px'}}>You are trialing {currentPlanName}. {!pmAttached && <>Update your payment method to auto renew your subscription after trial ends.</>}</p>
                                <p>Next Billing Date: <LocalizedDate timestamp={nextBillingTimestamp} locale={navigator.language} /></p>
                                <p style={{textAlign: 'left'}}>
                                    <Link className='text-white' style={{textDecoration: 'underline', color: '#fff'}} onClick={() => {handleBillingPortal()}}>Go to Billing Portal</Link>
                                    <Link className='text-white' style={{textDecoration: 'underline', color: '#fff', marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); navigate("/plan")}}>My Plan</Link>
                                </p>
                            </div>
                        </Panel>
                    </StyledCollapse>
            </div>
        }
        </>
    );
};

export default TrialUpgrade;
