import React, {useState, useEffect} from 'react';
import { Alert } from 'antd';
import { SoundOutlined } from '@ant-design/icons';
import './PromoBanner.css';

const PromoBanner = ({onBannerClose=null}) => {
  const [showBanner, setShowBanner] = useState(true);

  const bannerStyle = {
    // position: 'fixed',
    // top: '50%',
    // left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '680px',
    zIndex: '999',
    background: 'rgba(26, 35, 126, 0.95)',
    borderRadius: '10px'
  };

  const contentStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const descriptionHtml = `
    <p><b style="font-weight: normal;">Start with 7 day free trial</b></p>
    <h4 style="color: #fff; font-weight: 600; font-size: 1.1rem;">Then </h4>
    <p>upto 80% lifetime discount on Voiceover monthly plans. Get upto 84% lifetime discount in Voiceover yearly plans.</p>
    <p>50% lifetime discount on Dubbing and Writer monthly plans and 60% lifetime discount in Dubbing and Writer yearly plans.</p>
    <div style="margin-top: 20px; font-size: 0.9rem; opacity: 0.7; font-style: italic;">How to apply: Go to My Plan ->  Select Plan -> Subscribe</div>
    <p style="margin-top: 20px;">Go to <a style="color: #fff!important;" href="/plan?popup=true">payment</a></p>
  `;

  useEffect(() => {
    // Check if the sessionStorage flag for the banner is set
    const bannerFlag = sessionStorage.getItem('promoBClosed');
    if (bannerFlag === 'true') {
      //setShowBanner(false);
    }
  }, []);

  const handleBannerClose = () => {
    // Set the sessionStorage flag to indicate the banner has been closed
    sessionStorage.setItem('promoBClosed', 'true');
    setShowBanner(false);
    if(onBannerClose) {
      onBannerClose();
    }    
  };

  const overlayStyle = {
    position: 'fixed',   // Fixed position to cover the whole screen
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    zIndex: 999         // High z-index to ensure it's on top of other content
  };

  return showBanner ? (
    <>
    <div style={overlayStyle} onClick={handleBannerClose}></div>
    <div className='promo-banner' style={bannerStyle}>
      <Alert
        message={
          <div style={contentStyle}>
            <span style={{ fontSize: '1.7rem', fontWeight: '600' }}>80% Lifetime Special Discount</span>
          </div>
        }
        description={<div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />}
        type="info"
        closable
        onClose={handleBannerClose}
      />
    </div>
    </>
  ) : null;
};

export default PromoBanner;
