import React from 'react';

const DocumentTranslationIcon = ({ color = 'black', size = "1.3rem" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="1" width="18" height="22" rx="2" ry="2" stroke="currentColor" strokeWidth="2" fill="none"/>
    <line x1="6" y1="6" x2="18" y2="6" stroke="currentColor" strokeWidth="2"/>
    <line x1="6" y1="10" x2="18" y2="10" stroke="currentColor" strokeWidth="2"/>
    <line x1="6" y1="14" x2="14" y2="14" stroke="currentColor" strokeWidth="2"/>
    <circle cx="18" cy="18" r="3" stroke="currentColor" strokeWidth="2" fill="none"/>
    <path d="M15 18h6" stroke="currentColor" strokeWidth="2"/>
    <path d="M17 16v4" stroke="currentColor" strokeWidth="2"/>
    <path d="M18 20l-1-1" stroke="currentColor" strokeWidth="2"/>
    <path d="M18 20l1-1" stroke="currentColor" strokeWidth="2"/>
    <line x1="3" y1="22" x2="9" y2="22" stroke="currentColor" strokeWidth="2"/>
    <line x1="15" y1="22" x2="21" y2="22" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

export default DocumentTranslationIcon;
