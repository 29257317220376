import React from 'react';

const CheckCircleIconLarge = () => {
  return (
    <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="check-circle"
          width="1.6em"
          height="1.6em"
          fill="currentColor"
          aria-hidden="true"
          style={{color: '#52c41a'}}
        >
          <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path>
          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
        </svg>
  );
};

export default CheckCircleIconLarge;