import React from 'react';
import { Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import StripeLogo from 'icons/StripeLogo';

const { Paragraph } = Typography;

const PaymentCommonInfo = () => (
  <div className='payment-info-common' style={{background: 'rgb(249 255 243)', padding: '10px'}}>
    <Paragraph>
      <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: '8px' }} />
      Change or cancel plan anytime
    </Paragraph>
    <Paragraph>
      <span style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }}>
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: '8px' }} />
        100% secured payment with
      </span>
      <StripeLogo />
    </Paragraph>
    <Paragraph>
      <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: '8px' }} />
      30 days money back guarantee
    </Paragraph>
  </div>
);

export default PaymentCommonInfo;
