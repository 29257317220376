import React, { useRef, useState } from 'react';
import { Button, Radio } from 'antd';
import "./PlanComponentPopup.css"
import PlansComponent from './PlansComponent';


const PlanComponentPopup = ({ onCancelled, handle_change_plan=null }) => { 
  return (
    <div className="pcp popup-wrapper">
    <div className="popup">
    <button className="close-button" onClick={() => {onCancelled(false);}}>
        &times;
    </button> 
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '60px'}}>
    <PlansComponent showCurrentPlan={false} showTabs={false} handle_change_plan={handle_change_plan} />        
    </div>
    </div>
    <div className="backdrop"></div>
    </div>
  );
};

export default PlanComponentPopup;
