import React, { useState, useEffect } from 'react';
import { Radio, Checkbox } from 'antd';
import "./PlanTypeRadioButton.css";

const PlanTypeRadioButton = ({ options, handleChange, initialValue }) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleRadioChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    handleChange(newValue);
  };

  return (
    <Radio.Group buttonStyle="solid" onChange={handleRadioChange} value={selectedValue}>
      {options.map((option, index) => (
        <Radio.Button key={option} disabled={option === 'Dubbing(soon)'} value={option} style={{ marginBottom: '10px', padding: '8px' }}>
          {option}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default PlanTypeRadioButton;
