import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import './PromoBanner.css';
import { Activity, Globe } from 'react-feather';
import DubbingIcon from 'icons/DubbingIcon';
import MessageIconCustom from 'icons/MessageIconCustom';
import TemplateListCustom from 'icons/TemplateListCustom';
import ImageIconCustom from 'icons/ImageIconCustom';
import TransformerIcon from 'icons/TransformerIcon';
import DocumentIcon from 'icons/DocumentIcon';
import { Card } from 'antd';

const InitialAssistantComponent = ({onBannerClose=null}) => {
  const [showBanner, setShowBanner] = useState(true);

  const StyledBanner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  z-index: 999;
  background: #fff;
  overflow-y: auto;

  // Media query for smaller screens
  @media (max-width: 768px) {
    width: 100%;
  }
`;

  useEffect(() => {
    // Check if the sessionStorage flag for the banner is set
    const bannerFlag = sessionStorage.getItem('promoBClosed');
    if (bannerFlag === 'true') {
      //setShowBanner(false);
    }
  }, []);

  const handleBannerClose = () => {
    // Set the sessionStorage flag to indicate the banner has been closed
    sessionStorage.setItem('promoBClosed', 'true');
    setShowBanner(false);
    if(onBannerClose) {
      onBannerClose();
    }    
  };

  const overlayStyle = {
    position: 'fixed',   // Fixed position to cover the whole screen
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    zIndex: 999
  };

  return showBanner ? (
    <>
    <div style={overlayStyle} onClick={handleBannerClose}></div>
    <StyledBanner>
        <StyledHeading style={{marginTop: '30px'}}>What do you want to start with?</StyledHeading>
        <ShortcutCardContainer>
            <ShortcutItemCard label={'Text to Speech Studio'} link={'/studio'} Icon={<Activity style={{width: '38px', height: '38px'}}/>} subheading="Create long form, multi-voice human sounding audio with highly customizable options"></ShortcutItemCard>
            {/* <ShortcutItemCard label={'AI ScriptBot'} link={'/ai-audio-script'} Icon={<ScriptIcon size='34px' />}></ShortcutItemCard> */}
            <ShortcutItemCard label={'Dubbing Studio'} link={'/transcriptions'} Icon={<DubbingIcon color='#fff' size='50px'/>} subheading="Dub your videos with our human assisted Dubbing Studio with advanced editing options"></ShortcutItemCard>
            <ShortcutItemCard label={'AI Chatbot & Doc Chat'} link={'/chat-with-ai'} Icon={<MessageIconCustom size='2em' />} subheading='Our next generation AI Chatbot with multiple models and Doc Chat features'></ShortcutItemCard>
            <ShortcutItemCard label={'Templates'} link={'/templates'} Icon={<TemplateListCustom size='2em' />} subheading='Pre-built writing templates to let you write content faster. Draft your compelling copy faster than ever.'></ShortcutItemCard>
            <ShortcutItemCard label={'AI Image Studio'} link={'/ai-image'} Icon={<ImageIconCustom size='2em' />} subheading='Generate stunning images for your articles or blog post with our image generator. Select different style and size options.'></ShortcutItemCard>
            <ShortcutItemCard label={'Translator'} link={'/templates/translator'} Icon={<Globe style={{width: '38px', height: '38px'}} />} subheading='Translate your content into 100+ languages instantly within seconds!'></ShortcutItemCard>
            <ShortcutItemCard label={'Transform Text'} link={'/templates/transformer'} Icon={<TransformerIcon size='43px' />} subheading='Transform your text into any presentable format with our innovative text transformer.'></ShortcutItemCard>
            <ShortcutItemCard label={'AI Writing Editor'} link={'/documents/new'} Icon={<DocumentIcon size='43px' />} subheading='Refine your draft copy to create long form content. Rephrase, simplify or generate next paragraph with our AI.'></ShortcutItemCard>
        </ShortcutCardContainer>
    </StyledBanner>
    </>
  ) : null;
};

const StyledHeading = styled.h3`
  display: flex,
  flex-wrap: wrap,
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 900;
`

const ShortcutCardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0px 10px 0px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const ShortcutItemCard = ({ label, link, Icon, subheading=null }) => (
  <StyledShortcutItemCard onClick={() => { window.location.href = link }}>
    {Icon && <StyledIcon>{Icon}</StyledIcon>}
    <StyledLabel>{label}</StyledLabel>
    {
      subheading && <p style={{color: '#dedede'}}>{subheading}</p>
    }
  </StyledShortcutItemCard>
);

const StyledShortcutItemCard = styled(Card)`
  width: 250px;
  margin: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  background: #4A90E2;
  cursor: pointer;

  &:hover {
    background: rgba(160, 34, 234, 0.8);
  }

  // Media query for smaller screens
  @media (max-width: 768px) {
    width: 100%; // Full width on smaller screens
  }
`;

const StyledIcon = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
`;

const StyledLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
`;

export default InitialAssistantComponent;
