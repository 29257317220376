import React, { useEffect, useState } from 'react';
import { Card, List, Button, Tooltip, Alert, Popconfirm, Popover, Form, Select, Input } from 'antd';
import { CheckCircleTwoTone, LoadingOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { CheckCircleOutlineRounded, CheckCircleRounded } from '@material-ui/icons';
import CrossIcon from 'icons/CrossIcon';
import PaymentCommonInfo from 'pages/voiceover/PaymentCommonInfo';

const StyledCard = styled(Card)`
  width: ${(props) => `${props.width || '360px'};`}
  margin: 16px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  text-align: left;

  &.selected {
    border: 2px solid #1890ff;
  }

  h2 {
    color: #1890ff;
    margin: 0;
    text-align: center;
  }

  p {
    color: #b8b8b8;
    font-size: 16px;
    margin: 0 0 16px;
    padding: 0 16px;
    text-align: center;
    font-weight: bold;
  }

  p b.title {
    color: rgb(106, 63, 255);
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }

  div.yearly-block {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 16px;
    padding: 0 16px;
    text-align: center;
    opacity: 0.6;
  }

  div.discounted-price {
    color: #000;
    font-size: 18px;
    padding: 0 16px;
    text-align: center;
    opacity: 0.6;
  }

  .ant-list-item {
    padding: 5px 10px
  }

  button {
    width: 100%;
    border-radius: 0;
  }
`;

const PackageCard = ({
  title,
  price,
  data,
  selectedPackage,
  handlePackageSelection,
  yearly,
  discountedPrice,
  originalPrice,
  disPriceFreq,
  disPriceShow,
  subscriptionInfo,
  yPriceOriginal,
  yPriceDis,
  yDis,
  mPriceOriginal,
  mPriceDis,
  mDis,
  trialStatus,
  trialPlan,
  currentPlanName,
  inprogres=false,
  firstMonthSubscribed=false,
  firstMonthPrice=false,
  width="360px"
}) => {
  const [currentPlanIsYearly, setCurrentPlanIsYearly] = useState(false);
  const [cancelPopupVisible, setCancelPopupVisible] = useState(false);
  const [reason, setReason] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleConfirm = () => {
    if (inprogres) return;
    handlePackageSelection(title.toLowerCase(), price, trialStatus, trialPlan, actionDetails["actionName"], reason, feedback);
    setCancelPopupVisible(false);
  };

  const handleVisibleChange = (visible) => {
    setCancelPopupVisible(visible);
  };

  useEffect(() => {
    if(subscriptionInfo) {
      setCurrentPlanIsYearly(subscriptionInfo.billing_cycle === "year");
    }
  }, [subscriptionInfo]);


  const GetActionDetails = () => {
    let actionLabel = '';
    let actionName = '';
    let desc = '';
    let showFirstMonthPrice = false;

    // let noTrialPlans = ["voiceover - standard", "voiceover - premium", "voiceover - pro", 
    //                     "dubbing - standard", "dubbing - premium", "dubbing - pro", 
    //                     "writer - standard", "writer - premium", "writer - pro"];
    let noTrialPlans = ["voiceover - pro", 
                        "dubbing - pro", 
                        "writer - pro"];
    let planName = title.toLowerCase();

    let trialAllowed = !noTrialPlans.includes(planName);
  
    if (trialStatus === "no_trial_no_plan") {
      actionLabel = 'Start 7-Day Free Trial';
      actionName = 'start_trial';

      if(trialAllowed) {
        if(yearly) {
            desc = 'Begin with a 7-day free trial. Enjoy a '+ yDis +'% lifetime discount thereafter.'
        }
        else {
            desc = 'Begin with a 7-day free trial. Enjoy a '+ mDis +'% lifetime discount thereafter.'
        } 
      }
      else {
        actionLabel = 'Buy this plan';
        actionName = 'buy_plan';
        if(yearly) {
          desc = 'Buy this plan for '+ yDis +'% lifetime discount'
        }
        else {
          desc = 'Buy this plan for '+ mDis +'% lifetime discount'
        } 
      }
         
    } else if (trialStatus.toLowerCase() === "trialing") {
      showFirstMonthPrice = true;
      if (currentPlanName && currentPlanName.toLowerCase() === title.toLowerCase() && currentPlanIsYearly === yearly) {
        actionLabel = "End Trial";
        actionName = "end_trial";
        desc = 'You are trialing ' + title + '. To activate the plan End Trial.';
      } else {
        actionLabel = 'Buy this plan';
        actionName = 'buy_plan';
        if(yearly) {
          desc = 'Buy this plan for '+ yDis +'% lifetime discount'
        }
        else {
          desc = 'Buy this plan for '+ mDis +'% lifetime discount'
        } 
      }
    } else if (trialStatus === "trial_ended_no_plan") {

      if(trialPlan.toLowerCase() === title.toLowerCase() && currentPlanIsYearly === yearly) {
        desc = 'Your trial has ended. Please upgrade to continue'
      }
      else {
        if(yearly) {
          desc = 'Buy this plan with '+ yDis +'% lifetime discount';
        }
        else {
          desc = 'Buy this plan with '+ mDis +'% lifetime discount';
        }        
      }
      actionLabel = 'Buy this plan';
      actionName = 'buy_plan';

    } else if (trialStatus === "trial_ended_active_plan") {
      if (currentPlanName && currentPlanName.toLowerCase() === title.toLowerCase() && currentPlanIsYearly === yearly) {
        actionLabel = "Cancel Plan";
        actionName = "cancel_plan";
        desc = 'Your current active plan.'
        if(subscriptionInfo.cancel_at_period_end) {
          actionLabel = "Renew Plan";
          actionName = "renew";
          desc = 'This plan is scheduled to cancel after current billing period.'
        }
      } else {
        actionLabel = 'Change Plan';
        actionName = 'change_plan';
        if(yearly) {
          desc = 'Change to this plan with '+ yDis +'% lifetime discount'
        }
        else {
          desc = 'Change to this plan with '+ mDis +'% lifetime discount'
        } 
      }
    } else if (trialStatus === "no_trial_active_plan") {
      if (currentPlanName && currentPlanName.toLowerCase() === title.toLowerCase() && currentPlanIsYearly === yearly) {
        actionLabel = "Cancel Plan";
        actionName = "cancel_plan";
        desc = 'Your current active plan.'
        if(subscriptionInfo.cancel_at_period_end) {
          actionLabel = "Renew Plan";
          actionName = "renew";
          desc = 'This plan is scheduled to cancel after current billing period.'
        }
      } else {
        actionLabel = 'Change Plan';
        actionName = 'change_plan';
        if(yearly) {
          desc = 'Change to this plan with '+ yDis +'% lifetime discount'
        }
        else {
          desc = 'Change to this plan with '+ mDis +'% lifetime discount'
        }
      }
    }
  
    return {
      actionLabel: actionLabel,
      actionName: actionName,
      desc: desc,
      showFirstMonthPrice: false //showFirstMonthPrice
    };
  };

  const getActionClassName = () => {
    let className = "unmixr-primary5";
    let actionName = GetActionDetails()['actionName'];
    // Determine the class name based on actionName
    if (actionName === 'cancel_plan') {
      className = 'unmixr-danger';
    } else if (actionName === 'buy_plan') {
      className = 'unmixr-primary5';
    }
    else if (actionName === 'renew') {
      className = 'unmixr-warning';
    } else if (actionName === 'end_trial') {
      className = 'unmixr-light-green';
    }
    return className;
  }

  let actionDetails = GetActionDetails();
  

  return (
    <StyledCard width={width} className={selectedPackage === title.toLowerCase() && currentPlanIsYearly === yearly ? 'selected' : ''}>
      <h2 style={{color: '#0073e6'}}>{title}<>{actionDetails.actionName === "end_trial" && <span style={{fontWeight: '600', color: '#04e604'}}> (trialing)</span>}</></h2>
      {
        false && actionDetails.showFirstMonthPrice && <p style={{color: 'orange', fontSize: '1.1rem', marginBottom: '0px', marginTop: '5px'}}>First month for <b style={{fontSize: '1.3rem'}}>$1.00</b> then</p>
      }
      {
        false && yearly && yDis > 0 && yPriceOriginal && 
        <div className="discounted-price">
          <span style={{ marginLeft: '5px' }}>
            <b style={{textDecoration: 'line-through'}}>${yPriceOriginal.toFixed(2)}</b>{<>/ <span style={{fontSize: '0.8rem'}}>month</span></>}
          </span>
        </div>
      }
      {
        false && !yearly && mDis > 0 && mPriceOriginal && 
        <div className="discounted-price">
          <span style={{ marginLeft: '5px' }}>
            <b className='title' style={{textDecoration: 'line-through'}}>${mPriceOriginal.toFixed(2)}</b>{' /month'}
          </span>
        </div>
      }
      <p><b className='title'>${yearly && yPriceDis.toFixed(2)}{!yearly && mPriceDis.toFixed(2)}</b> <b style={{textDecoration: 'line-through', fontSize: '0.9rem'}}>${yPriceOriginal.toFixed(2)}</b> {<>/ <span style={{fontSize: '0.8rem'}}>month</span></>} {
        !yearly && mDis > 0 && 
          <b style={{fontSize: '1rem', color:"rgb(106, 63, 255)"}}>({mDis.toFixed(0)}% off)</b>  
      }
        
      {
        yearly && yDis > 0 && 
          <b style={{fontSize: '1rem',color: 'rgb(106, 63, 255)', }}>({yDis.toFixed(0)}% off)</b>
      }
      </p>
      {
        <>
          <>
            {
              actionDetails["actionName"] === "change_plan" && 
              <Popconfirm
                title="Are you sure you want to change your plan?"
                onConfirm={() => {if(inprogres)return false;handlePackageSelection(title.toLowerCase(), price, trialStatus, trialPlan, actionDetails["actionName"])}}
                onCancel={() => {}}
                okText="Change"
                cancelText="Cancel"
              >
                <Button
                  className={getActionClassName()}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    cursor: inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? 'wait' : 'pointer'
                  }}
                >
                  {inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? <LoadingOutlined /> : <></>}
                  {actionDetails['actionLabel']}
                </Button>
              </Popconfirm>
            }
            {
              actionDetails["actionName"] === "cancel_plan" && 
              <Popover
                content={
                  <Form layout="vertical">
                    <Form.Item label="Reason for cancelling (Optional)">
                      <Select value={reason} onChange={setReason} placeholder="Select a reason" style={{width: '260px'}}>
                        <Option value="customer_service">Customer service was less than expected</Option>
                        <Option value="low_quality">Quality was less than expected</Option>
                        <Option value="missing_features">Some features are missing</Option>
                        <Option value="switched_service">I’m switching to a different service</Option>
                        <Option value="too_complex">Ease of use was less than expected</Option>
                        <Option value="too_expensive">It’s too expensive</Option>
                        <Option value="unused">I don’t use the service enough</Option>
                        <Option value="other">Other reason</Option>
                      </Select>
                    </Form.Item>
                    {reason === 'other' && (
                      <Form.Item label="Please specify">
                        <Input value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                      </Form.Item>
                    )}
                    <Form.Item>
                      <Button type="primary" onClick={handleConfirm}>
                        Confirm
                      </Button>
                      <Button style={{marginLeft: '10px'}} type="default" onClick={() => {setCancelPopupVisible(false);}}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                }
                trigger="click"
                visible={cancelPopupVisible && !inprogres}
                onVisibleChange={handleVisibleChange}
              >
                <Button
                  className={getActionClassName()}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    cursor: inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? 'wait' : 'pointer'
                  }}
                >
                  {inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? <LoadingOutlined /> : <></>}
                  {actionDetails['actionLabel']}
                </Button>
              </Popover>
            }
            {
              actionDetails["actionName"] === "renew" && 
              <Popconfirm
                title="Confirmation Required"
                onConfirm={() => {if(inprogres)return false;handlePackageSelection(title.toLowerCase(), price, trialStatus, trialPlan, actionDetails["actionName"])}}
                onCancel={() => {}}
                okText="Renew"
                cancelText="Cancel"
              >
                <Button
                  className={getActionClassName()}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    cursor: inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? 'wait' : 'pointer'
                  }}
                >
                  {inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? <LoadingOutlined /> : <></>}
                  {actionDetails['actionLabel']}
                </Button>
              </Popconfirm>
            }
            {
              actionDetails["actionName"] === "end_trial" && 
              <Popconfirm
                title=<div style={{maxWidth: '360px'}}>If you have not updated the payment method yet, you will be redirected to update the payment method first.</div>
                onConfirm={() => {if(inprogres)return false;handlePackageSelection(title.toLowerCase(), price, trialStatus, trialPlan, actionDetails["actionName"])}}
                onCancel={() => {}}
                okText="End Trial"
                cancelText="Cancel"
              >
                <Button
                  className={getActionClassName()}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    cursor: inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? 'wait' : 'pointer'
                  }}
                >
                  {inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? <LoadingOutlined /> : <></>}
                  {actionDetails['actionLabel']}
                </Button>
              </Popconfirm>
            }
            {
              (actionDetails["actionName"] !== "change_plan" && actionDetails["actionName"] !== "end_trial" && actionDetails["actionName"] !== "cancel_plan" && actionDetails["actionName"] !== "renew") && 
              <Button
                  className={getActionClassName()}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    cursor: inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? 'wait' : 'pointer'
                  }}
                  onClick={() => {if(inprogres)return false;handlePackageSelection(title.toLowerCase(), price, trialStatus, trialPlan, actionDetails["actionName"])}}
                >
                  {inprogres && selectedPackage.toLowerCase() === title.toLowerCase() ? <LoadingOutlined /> : <></>}
                  {actionDetails['actionLabel']}
                </Button>
            }
          </>
          <Alert type='info' style={{fontSize: "0.9rem", textAlign: 'center', padding: '4px 10px', border: 'none', color: "#6c757d", fontWeight: 400}} description={<>{GetActionDetails()['desc']}</>}>
          </Alert>
        </>
      }

     
      <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item style={item.not_available ? { textDecoration: 'line-through', opacity: '0.4' } : null}>
          <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
          {
            item.heading && <h3 style={{fontWeight: 'bold', color: '#0073e6', letterSpacing: '1px', textTransform: 'uppercase', margin: '20px 0 10px'}}>{item.text}</h3>
          }
          {
            !item.heading && <>
            <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: '8px' }} />
            {!item.not_available && item.text}
            </>         
          }
          {
            item.not_available && 
            <>
              {item.text}
            </>            
          }
          </div>
          {item.not_available && (
            <Tooltip title={item.not_available}>
              <QuestionCircleTwoTone type="default" style={{ marginLeft: '8px' }} />
            </Tooltip>
          )}
        </List.Item>
      )}
    />
      <div style={{marginTop: '30px'}}>
        <hr />
      <PaymentCommonInfo/>
      </div>
    </StyledCard>
  );
};

export default PackageCard;
