import React, {  } from 'react';
import { Breadcrumb, Layout, Menu, theme, Button, Dropdown, Header } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';

import './NavBar.css';
import {
  LogoutOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  DollarCircleOutlined,
  UserOutlined,
  HistoryOutlined
} from '@ant-design/icons';
import { useState } from 'react';
const NavBar = ({userInfo}) => {
  
  const navigate = useNavigate();

  const handleLogOut = () => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/logout/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {}

    UseAPI(signal, url, "GET", payload)
    .then(function(data) {
        // handle data here
        if(data.code === 200) {
          localStorage.clear();
          navigate('/login');
        }
        else {
            message.error("Error connecting to server");
        }
    })
    .catch(function(err) {
        if (err.name === 'AbortError') {
            console.log('Request aborted');
        } else {
            //alert("Error");
            message.error("Error connecting to server");
        }
    });
  };

  const items = [
    {
      key: 'my_plan_top_nav',
      label: (
        <Link to="/plan">
          <DollarCircleOutlined />
          <span className="menu-item-link"> My Plan</span>
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to="/help">
          <QuestionCircleOutlined />
          <span className="menu-item-link"> Help & Support</span>
        </Link>
      ),
    },
    {
      key: '5',
      label: (
        <Link to="/settings">
          <SettingOutlined />
          <span className="menu-item-link"> Settings</span>
        </Link>
      ),
    },
    {
      key: '6',
      label: (
        <div onClick={() => handleLogOut()}>
          <LogoutOutlined />
          <span className="menu-item-link"> Logout</span>
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      style={{ float: 'right' }}
      menu={{
        items,
      }}
      placement="bottomRight"
      arrow
    >
      <div className="user-info">
        <div className="user-name">{userInfo && userInfo.first_name}</div>
        <div className="user-email">{userInfo && userInfo.email}</div>
      </div>
    </Dropdown>
  );
};

export default NavBar;
