import React, { useState, useContext, useEffect, useRef } from 'react';
import PageLayout from '../../layout/PageLayout';
import { Steps, List, Button, Select, message, Spin, Typography, Card, Alert, Switch, Tabs, Input, notification } from 'antd';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
const { Step } = Steps;
const { Option } = Select;
import styled from 'styled-components';
import PackageCard from './PackageCard';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import { StoreContext } from 'store';
import './index.css';
import { Fragment } from 'react';
import PlanTypeRadioButton from './PlanTypeRadioButton';
import CheckCircleIconLarge from 'icons/CheckCircleIconLarge';
import StripeLogo from 'icons/StripeLogo';
import PaymentCommonInfo from 'pages/voiceover/PaymentCommonInfo';
const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 18,
    }}
    spin
  />
);

const PlanDetails = ({ planDetails, change_plan_click_handler=null, trigger_subscription_status=null }) => {
  const [apiInProgress, setAPIInProgress] = useState(false);

  const { amount, status, current_period_start, current_period_end, cancel_at_period_end, addons } =
    planDetails;

  // Map from id to label
  const addonLabels = {
    '10kwords': '10000 words',
    '30kwoiceoverchars': '30000 voiceover characters',
    '60mintranscription': '60 minute transcription',
    '20images': '20 images',
    // add other mappings here...
  };

  const addonsWithLabels = addons.map((addon) => ({
    ...addon,
    label: addonLabels[addon.id],
  }));

  const columns = [
    {
      title: 'Addon',
      dataIndex: 'label',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
  ];

  const handleManageBilling = (event) => {
    setAPIInProgress(true);
    let payload = {
      action: "billing_portal"
    };
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/create-checkout-session/';
    let apiController = new AbortController();
    const signal = apiController.signal;

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
         window.location.href = data.url;
         setAPIInProgress(false);
      })
      .catch(function (err) {
        if (err.name === 'AbortError') {
          message.error('Requested aborted');
        } else {
          message.error('Error on processing request');
        }
        setAPIInProgress(false);
      });
  };

  const handleRenewPlan = (event) => {
    //setAPIInProgress(true);
    let payload = {
      action: "renew"
    };
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/create-checkout-session/';
    let apiController = new AbortController();
    const signal = apiController.signal;

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
        if(data.code === 200) {
          // if(trigger_subscription_status) {
          //   trigger_subscription_status();
          // }
          window.location.reload();
          notification.success({
            message: data.title || 'Your request has been processed successfully',
            description: data.message,
          });
        }
        else {
          notification.error({
            message: data.title||'Failed to process your request',
            description: data.message,
          });
        }
      })
      .catch(function (err) {
        notification.error({
          message: 'Failed to process your request',
          description: "Unfortunately, We could not process your request. Please retry after sometime. If the issue continues please reach out to us at support@unmixr.com",
        });
        //setAPIInProgress(false);
      });
  };

  const addDays = (dt, days) => {
    let date = new Date(dt);
    date.setDate(date.getDate() + days);
    return date;
  };

  function convertCase(str) {
    var words = str.split(' ');
    var convertedWords = words.map(function(word) {
      if (word === '-') {
        return '-';
      } else {
        var firstChar = word.charAt(0).toUpperCase();
        var restOfWord = word.slice(1).toLowerCase();
        return firstChar + restOfWord;
      }
    });
  
    return convertedWords.join(' ');
  }  

  return (
    <div
      className="pricing-component"
      style={{ borderRadius: '10px', marginTop: '5px', position: 'relative' }}
    >
      <span style={{position: 'absolute', top: '-30px', left: '-10px', fontSize: '0.8rem', fontWeight: '600', color: '#6d6d6d'}}>YOUR PLAN</span>
      <Title level={4} style={{ marginBottom: '0px', color: 'rgb(20, 20, 20)', fontSize: '1.4rem', fontWeight: '900' }}>
        { convertCase(planDetails.plan) } ({status})
        {/* {amount && <p>(${amount} / {planDetails.billing_cycle})</p>} */}
      </Title>
      {status.toLowerCase() !== "trialing" && 
        <p style={{ marginBottom: '5px', fontSize: '0.9rem' }}>
          Current period: {new Date(current_period_start).toLocaleDateString()} -{' '}
          {new Date(current_period_end).toLocaleDateString()}
        </p>
      }
      {status.toLowerCase() === "trialing" && 
        <p style={{ marginBottom: '5px', fontSize: '0.9rem' }}>
          Your 7 day free trial ending on {new Date(current_period_end).toLocaleDateString()}
        </p>
      }
      {cancel_at_period_end && (
        <p style={{ marginBottom: '10px' }}>
          <div
            style={{
              marginBottom: '10px',
              backgroundColor: '#f8d7da',
              borderColor: '#f5c6cb',
              color: '#721c24',
              padding: '3px',
              fontSize: '0.9rem',
              maxWidth: '400px'
            }}
          >Subscription is scheduled to cancel after current billing cycle. Go to your billing portal to renew the subscription</div>
        </p>
      )}
      {!cancel_at_period_end && (
        <p style={{ marginBottom: '10px' }}>
          <div
            className='next-billing-alert'
            style={{ marginBottom: '20px', backgroundColor: '#d9f9d9', color: '#000', fontSize: '0.9rem', paddingBottom: '3px' }}
          >{'Next billing at ' + addDays(current_period_end, 1).toLocaleDateString()}</div>
        </p>
      )}
      {/* <p style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '500px', textAlign: 'left'}}>
        To change or cancel your plan or check your invoice, please click on <span style={{color: 'green', fontWeight: '600'}}>'Manage Your Billing'</span>. 
        If you have any question or need support please send us email at <a href='mailto:support@unmixr.com'>support@unmixr.com</a></p> */}
      {
        !cancel_at_period_end && <a
          disabled={apiInProgress}
          className='change-plan-link'
          onClick={(event) => {if(change_plan_click_handler)change_plan_click_handler()}}
          style={{ marginBottom: '30px', padding: '4px 15px', marginRight: '10px', fontWeight: '600', color: 'rgb(0, 120, 212)' }}
        >
          Change/Cancel Plan
        </a>
      }
      {
        cancel_at_period_end && <a
          disabled={apiInProgress}
          className='change-plan-link'
          onClick={(event) => {handleRenewPlan()}}
          style={{ marginBottom: '30px', padding: '4px 15px', marginRight: '10px', fontWeight: '600', color: 'rgb(0, 120, 212)' }}
        >
          Renew Plan
        </a>
      }
      <Button
        disabled={apiInProgress}
        type="primary"
        onClick={(event) => handleManageBilling(event)}
        style={{ marginBottom: '30px', padding: '4px 15px' }}
      >
        {apiInProgress && (
          <Spin
            indicator={antIcon}
            style={{
              // color: 'white',
              marginRight: '10px',
              marginTop: '3px',
            }}
          />
        )}
        Go to Billing Portal
      </Button>
    </div>
  );
};

const PlansComponent = ({showCurrentPlan=true, showTabs=true, handle_change_plan=null, handleShowResPopup=null, componentWidth='360px'}) => {
  const [isYearly, setIsYearly] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [addedAddons, setAddedAddons] = useState({});
  const [addonsQuantity, setAddonsQuantity] = useState({});
  const [summary, setSummary] = useState({
    package: '',
    addons: [],
    totalCost: 0,
    yearly: 0,
    monthly: 0,
    yearlyOriginal: 0,
    monthlyOriginal: 0,
    package_price: 0.00
  });
  const [checkoutInProgress, setCheckoutInProgress] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [trialStatus, setTrialStatus] = useState(null);
  const [trialPlan, setTrialPlan] = useState(null);
  const [currentPlanName, setCurrentPlanName] = useState(null);
  const [appSumoPlan, setAppSumoPlan] = useState({});
  const [pageReady, setPageReady] = useState(false);
  const [currentPlanType, setCurrentPlanType] = useState("Voiceover");
  const [appSumoCode, setAppSumoCode] = useState(null);
  const [codeRedeemInProgress, setCodeRedeemInProgress] = useState(false);

  const [triggerSubscriptionStatusRefresh, setTriggerSubscriptionStatusRefresh] = useState(false);
  const [firstMonthSubscribed, setFirstMonthSubscribed] = useState(false);
  const [firstMonthPrice, setFirstMonthPrice] = useState(false);

  const [adminUser, setAdminUser] = useState(false);
  
  const { search } = useLocation();
  const { state, dispatch } = useContext(StoreContext);

  const codeInputRef = useRef(null);

  useEffect(() => {
    if(localStorage.getItem("k_") === "true") {
      setAdminUser(true);
    }
    else {
      setAdminUser(false);
    }
  }, []);

  const getTabFromQuery = () => {
    const params = new URLSearchParams(search);
    return params.get('tab');
  };

  let defaultTab = getTabFromQuery();
  if(defaultTab === "plans") {
    defaultTab = "all-plans";
  }

  const resetPackage = () => {
    setSelectedPackage('');
    setSummary({
      package: '',
      addons: [],
      totalCost: 0,
      yearly: 0,
      monthly: 0,
      yearlyOriginal: 0,
      monthlyOriginal: 0,
      package_price: 0.00
    });
  }

  const handleSwitchChange = (checked) => {
    setIsYearly(checked);
  };

  let oldTitle = document.title;
  useEffect(() => {
    document.title = 'My plan';

    // Optionally, you can return a cleanup function that sets the title back when the component unmounts
    return () => {
      document.title = oldTitle;
    };
  }, []);

  const [selectedPackage, setSelectedPackage] = useState('');

  const handlePackageSelection = (packageType, price, trial_status, trial_plan, action, reason=null, feedback=null) => {
    //console.log(packageType, price);
    setSelectedPackage(packageType);
    //setSummary((prev) => ({ ...prev, package: packageType, package_price: price }));

    //setCurrentStep(currentStep + 1);

    setCheckoutInProgress(true);
    let payload = {
      plan: packageType.toLowerCase(),
      trial_status: trial_status,
      trial_plan: trial_plan,
      billing_cycle: isYearly?12:1,
      action: action,
      reason: reason,
      feedback: feedback
    };
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/create-checkout-session/';
    let apiController = new AbortController();
    const signal = apiController.signal;

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
        setCheckoutInProgress(false);
        if (data.code === 200) {
          if(data.type === "no_redirect") {
            if(action === "start_trial") {
              if(handleShowResPopup) {
                handleShowResPopup("trial-success");
              }
            }
            else {
              notification.success({
                message: data.title||'Your request has been processed successfully',
                description: data.message,
              });
            }            
            setTimeout(function() {
              setTriggerSubscriptionStatusRefresh(!triggerSubscriptionStatusRefresh);
            }, 500);
          }
          else {
            window.location.href = data.url;
          }          
        }
        else if(data.code === 400) {
          notification.error({
            message: data.title||'',
            description: data.message,
          });
        }
        else if(data.code === 500) {
          notification.error({
            message: data.title||'',
            description: data.message,
          });
        }      
        
      })
      .catch(function (err) {
        setCheckoutInProgress(false);
        notification.error({
          message: 'Request Error',
          description: "Unfortunately, we could not process your request. Please wait a moment and retry. If you still get this error, please contact us at support@unmixr.com",
        });
      });

  };

  const handlePlanTypeChangeRadioGroup = (value) => {
    setCurrentPlanType(value);
  };

  const steps = [
    {
      title: 'Select Plan',
      content: (
        <div>
          <PlanTypeRadioButton options={["Voiceover", "Dubbing", "Writer"]} initialValue={currentPlanType} handleChange={handlePlanTypeChangeRadioGroup}/>
          {
            currentPlanType === "Voiceover" && 
            <>
            {/* <h2 style={{fontSize: '1.3rem', fontWeight: '600', marginTop: '30px', color: 'rgb(106, 63, 255)'}}>Voiceover</h2>
            <p className='alert alert-info'>To apply the discount, use code <b>SPECIAL</b> during checkout.</p> */}
              <div className="package-container">
                <PackageCard
                  title="Voiceover - Standard"
                  data={[
                    { heading: true, text: 'Text to Speech' },
                    { text: '500,000 voiceover characters (~10 hours of Audio)' },
                    { text: '1 instant voice cloning profile' },
                    { text: '50K voice cloning characters' },
                    { text: 'Unlimited preview' },
                    { text: 'Generate Long audio (up to 3.5 hours)' },
                    { text: '1,300 AI Voices in 100+ languages' },
                    { text: 'Access to all Multilingual voices' },
                    { text: 'Emotional voices' },
                    { text: 'Background music and Sound Effects' },
                    { text: 'Voice customization (Voice Intensity, Vocal Pitch, Speaking Speed, Volume)' },
                    { text: 'Import Text, PDF, DOCX, ePub, URL, Subtitles in Voice Studio' },
                    { text: 'Download audio in MP3, WAV, FLAC, OGG and AIFF' },
                    { text: 'API access (soon)' },
                    { text: 'Subtitles', not_available: 'Not Available in this plan' },
                    { text: 'Unlimited projects' },
                    { text: 'Unlimited audio storage' },
                    { text: 'Full rights on commercial use' },

                    { heading: true, text: 'Chatbot, Doc Chat & Copywriting' },
                    { text: '300,000 words to use in AI Chat & Copywriting tools (GPT-3.5, Gemini Pro, Mistral, Gemma, and DocChat)' },
                    { text: '50,000 premium words to use with GPT-4o, Claude-3.5, Gemini Pro-1.5, and other advanced models' },
                    { text: 'AI Chatbot (GPT-4o, Claude-3.5, Gemini Pro, Cohere, Gemma, Mistral Large, LLaMA-3.1, Perplexity)' },
                    { text: 'Chat with multiple documents' },
                    { text: 'Copywriting templates' },

                    { heading: true, text: 'Document Translation' },
                    { text: '2 Document Translation' },

                    { heading: true, text: 'More' },
                    { text: 'Standard Support' },
                    { text: 'Addons (soon)', not_available: 'Not Available in this plan' }
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={25}
                  yPriceDis={5.63}
                  yDis={77.5}
                  mPriceOriginal={25}
                  mPriceDis={7.50}
                  mDis={70}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
                <PackageCard
                  title="Voiceover - Premium"
                  data={[
                    { heading: true, text: 'Text to Speech' },
                    { text: '1 million voiceover characters (~20 hours of Audio)' },
                    { text: '10 instant voice cloning profiles' },
                    { text: '100K voice cloning characters' },
                    { text: 'Unlimited preview' },
                    { text: 'Generate Long audio (up to 3.5 hours)' },
                    { text: '1,300 AI Voices in 100+ languages' },
                    { text: 'Access to all Multilingual voices' },
                    { text: 'Emotional voices' },
                    { text: 'Background music and Sound Effects' },
                    { text: 'Voice customization (Voice Intensity, Vocal Pitch, Speaking Speed, Volume)' },
                    { text: 'Import Text, PDF, DOCX, ePub, URL, Subtitles in Voice Studio' },
                    { text: 'Download audio in MP3, WAV, FLAC, OGG and AIFF' },
                    { text: 'API access (soon)' },
                    { text: 'Subtitles' },
                    { text: 'Unlimited projects' },
                    { text: 'Unlimited audio storage' },
                    { text: 'Full rights on commercial use' },

                    { heading: true, text: 'Chatbot, Doc Chat & Copywriting' },
                    { text: '600,000 words to use in AI Chat & Copywriting tools (GPT-3.5, Gemini Pro, Mistral, Gemma, and DocChat)' },
                    { text: '100,000 premium words to use with GPT-4o, Claude-3.5, Gemini Pro-1.5, and other advanced models' },
                    { text: 'AI Chatbot (GPT-4o, Claude-3.5, Gemini Pro, Cohere, Gemma, Mistral Large, LLaMA-3.1, Perplexity)' },
                    { text: 'Chat with multiple documents' },
                    { text: 'Copywriting templates' },

                    { heading: true, text: 'Document Translation' },
                    { text: '5 Document Translation' },

                    { heading: true, text: 'More' },
                    { text: 'Premium Support' },
                    { text: 'Addons (soon)' }
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={45}
                  yPriceDis={10.13}
                  yDis={77.5}
                  mPriceOriginal={45}
                  mPriceDis={13.50}
                  mDis={70}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
                <PackageCard
                  title="Voiceover - Pro"
                  data={[
                    { heading: true, text: 'Text to Speech' },
                    { text: 'Unlimited voiceover characters' },
                    { text: 'Unlimited instant voice cloning profiles' },
                    { text: '300K voice cloning characters' },
                    { text: 'Unlimited preview' },
                    { text: 'Generate Long audio (up to 3.5 hours)' },
                    { text: '1,300 AI Voices in 100+ languages' },
                    { text: 'Access to all Multilingual voices' },
                    { text: 'Emotional voices' },
                    { text: 'Background music and Sound Effects' },
                    { text: 'Voice customization (Voice Intensity, Vocal Pitch, Speaking Speed, Volume)' },
                    { text: 'Import Text, PDF, DOCX, ePub, URL, Subtitles in Voice Studio' },
                    { text: 'Download audio in MP3, WAV, FLAC, OGG and AIFF' },
                    { text: 'API access (soon)' },
                    { text: 'Subtitles' },
                    { text: 'Unlimited projects' },
                    { text: 'Unlimited audio storage' },
                    { text: 'Full rights on commercial use' },

                    { heading: true, text: 'Chatbot, Doc Chat & Copywriting' },
                    { text: 'Unlimited words to use in AI Chat & Copywriting tools (GPT-3.5, Gemini Pro, Mistral, Gemma, and DocChat)' },
                    { text: '150,000 premium words to use with GPT-4o, Claude-3.5, Gemini Pro-1.5, and other advanced models' },
                    { text: 'AI Chatbot (GPT-4o, Claude-3.5, Gemini Pro, Cohere, Gemma, Mistral Large, LLaMA-3.1, Perplexity)' },
                    { text: 'Chat with multiple documents' },
                    { text: 'Copywriting templates' },

                    { heading: true, text: 'Document Translation' },
                    { text: '8 Document Translation' },

                    { heading: true, text: 'More' },
                    { text: 'Pro Support' },
                    { text: 'Addons (soon)' }
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={65}
                  yPriceDis={14.63}
                  yDis={77.5}
                  mPriceOriginal={65}
                  mPriceDis={19.50}
                  mDis={70}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
              </div>
            </>
          }
          {
            currentPlanType === "Dubbing" && 
            <>
              {/* <h2 style={{fontSize: '1.3rem', fontWeight: '600', marginTop: '30px', color: 'rgb(106, 63, 255)'}}>Transcription</h2>
              <p className='alert alert-info'>To apply the discount, use code <b>SPECIAL</b> during checkout.</p> */}
              <div className="package-container">
                <PackageCard
                  title="Dubbing - Standard"
                  data={[
                    { text: '720 credits (3 hours Dubbing)' },
                    { text: '100,000 words (to use in AI Chat & Copywriting tools)' },
                    { text: '100,000 voiceover characters(~2 hours audio)' },
                    { text: 'Unlimited audio or video files' },
                    { text: 'Dubbing in 100+ Languages' },
                    { text: 'Access to AI Chat & Copywriting tools (AI Chat, AI Templates, AI Translator, AI Editor)' },
                    { text: 'Download transcription with timestamp and speaker customization' },
                    { text: 'Subtitles in 100+ languages' },
                    { text: 'Manage documents' },
                    { text: 'Standard Support' },
                    { text: 'Addons (soon)' },
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={42.00}
                  yPriceDis={16.80}
                  yDis={60}
                  mPriceOriginal={42.00}
                  mPriceDis={21.00}
                  mDis={50}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
                <PackageCard
                  title="Dubbing - Premium"
                  data={[
                    { text: '1500 credits (6.25 hours Dubbing)' },
                    { text: '200,000 words (to use in AI Chat & Copywriting tools)' },
                    { text: '200,000 voiceover characters(~4 hours audio)' },
                    { text: 'Unlimited audio or video files' },
                    { text: 'Dubbing in 100+ Languages' },
                    { text: 'Access to AI Chat & Copywriting tools (AI Chat, AI Templates, AI Translator, AI Editor)' },
                    { text: 'Download transcription with timestamp and speaker customization' },
                    { text: 'Subtitles in 100+ languages' },
                    { text: 'Manage documents' },
                    { text: 'Premium Support' },
                    { text: 'Addons (soon)' },
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={79.00}
                  yPriceDis={31.60}
                  yDis={60}
                  mPriceOriginal={79.00}
                  mPriceDis={39.50}
                  mDis={50}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
                <PackageCard
                  title="Dubbing - Pro"
                  data={[
                    { text: '2400 credits (10 hours Dubbing)' },
                    { text: '300,000 words (to use in AI Chat & Copywriting tools)' },
                    { text: '300,000 voiceover characters(~6 hours audio)' },
                    { text: 'Unlimited audio or video files' },
                    { text: 'Dubbing in 100+ Languages' },
                    { text: 'Access to AI Chat & Copywriting tools (AI Chat, AI Templates, AI Translator, AI Editor)' },
                    { text: 'Download transcription with timestamp and speaker customization' },
                    { text: 'Subtitles in 100+ languages' },
                    { text: 'Manage documents' },
                    { text: 'Pro Support' },
                    { text: 'Addons (soon)' },
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={119.00}
                  yPriceDis={47.60}
                  yDis={60}
                  mPriceOriginal={119.00}
                  mPriceDis={59.50}
                  mDis={50}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
              </div>
            </>
          }

          {
             currentPlanType === "Writer" && 
             <>
             {/* <h2 style={{fontSize: '1.3rem', fontWeight: '600', marginTop: '30px', color: 'rgb(106, 63, 255)'}}>Writer</h2>
             <p className='alert alert-info'>To apply the discount, use code <b>SPECIAL</b> during checkout.</p> */}
              <div className="package-container">
                <PackageCard
                  title="Writer - Standard"
                  data={[
                    { text: '100,000 words' },
                    { text: '50,000 premium words' },
                    { text: 'GPT-4 words', not_available: 'Not Available in this plan' },
                    { text: 'Doc Chat (Chat with PDF, Docx, Text files and more)' },
                    { text: '20 images' },
                    { text: '50+ AI templates' },
                    { text: 'AI Chatbot(GPT-3.5, GPT-4, GPT-4-O, GPT-4-Turbo, Gemini-Pro, Gemma, Mistral Large, Perplexity)' },
                    { text: 'AI writing editor' },
                    { text: 'Translation' },
                    { text: 'Text Transformer' },
                    { text: 'Addons (soon)', not_available: 'Not Available in this plan' },
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={5.99}
                  yPriceDis={2.40}
                  yDis={60}
                  mPriceOriginal={5.99}
                  mPriceDis={2.99}
                  mDis={50}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
                <PackageCard
                  title="Writer - Premium"
                  data={[
                    { text: '300,000 words' },
                    { text: '100,000 premium words' },
                    { text: 'Doc Chat (Chat with PDF, Docx, Website, Audio, Video and more)' },  
                    { text: '50 image Credits' },
                    { text: '50+ AI templates' },
                    { text: 'AI Chatbot(GPT-3.5, GPT-4, GPT-4-O, GPT-4-Turbo, Claude-2, Claude-3-opus, Claude-3-Sonnet, Claude-3-Haiku, Cohere, Gemini-Pro, Gemma, Mistral Large, LLaMA-2, Perplexity)' },
                    { text: 'AI writing editor' },
                    { text: 'Translation' },
                    { text: 'Text Transformer' },
                    { text: 'Addons (soon)' },
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={10.99}
                  yPriceDis={4.40}
                  yDis={60}
                  mPriceOriginal={10.99}
                  mPriceDis={5.50}
                  mDis={50}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
                <PackageCard
                  title="Writer - Pro"
                  data={[
                    { text: 'Unlimited words' },
                    { text: '150,000 premium words' },
                    { text: 'Doc Chat (Chat with PDF, Docx, Website, Audio, Video and more)' },
                    { text: '100 images' },
                    { text: '50+ AI templates' },
                    { text: 'AI Chatbot(GPT-3.5, GPT-4, GPT-4-O, GPT-4-Turbo, Claude-2, Claude-3-opus, Claude-3-Sonnet, Claude-3-Haiku, Cohere, Gemini-Pro, Gemma, Mistral Large, LLaMA-2, Perplexity)' },
                    { text: 'AI writing editor' },
                    { text: 'Translation' },
                    { text: 'Pro Support' },
                    { text: 'Addons (soon)' },
                  ]}
                  selectedPackage={selectedPackage}
                  handlePackageSelection={handlePackageSelection}
                  yearly={isYearly}
                  subscriptionInfo={subscriptionInfo}
                  yPriceOriginal={35}
                  yPriceDis={14.00}
                  yDis={60}
                  mPriceOriginal={35}
                  mPriceDis={17.50}
                  mDis={50}
                  trialStatus={trialStatus}
                  trialPlan={trialPlan}
                  currentPlanName={currentPlanName}
                  inprogres={checkoutInProgress}
                  firstMonthSubscribed={firstMonthSubscribed}
                  firstMonthPrice={firstMonthPrice}
                  width={componentWidth}
                />
              </div>
             </>
          }
        </div>
      ),
    },
    {
      title: 'Payment',
      content: <div className="totals"></div>,
    },
  ];

  const StepsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  useEffect(() => {
    // Call API to create transcription
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/subscription-status/';
    let apiController = new AbortController();
    const signal = apiController.signal;
    let payload = {};

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
        //console.log(data);
        if (data.code === 200) {
          dispatch({ type: 'UPDATE_STATE', payload: { show_login_modal: false } });
          setSubscriptionInfo(data.plan);
          setTrialStatus(data.trial_status);
          setTrialPlan(data.trial_plan);
          setCurrentPlanName(data.plan_name);
          setFirstMonthPrice(data.first_month_price);
          setAppSumoPlan(data.appsumo_plan);
          setIsYearly(data.plan && data.plan.billing_cycle === "year");
          setFirstMonthSubscribed(data.first_month_subscribed);
        } else if (data.code === 401) {
          dispatch({ type: 'UPDATE_STATE', payload: { show_login_modal: true } });
          //window.location.href = "/login";
        } else {
          dispatch({ type: 'UPDATE_STATE', payload: { show_login_modal: false } });
          message.error('Could not fetch subscription detail');
        }
        setPageReady(true);
      })
      .catch(function (err) {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          message.error('Error while fetching subscription detail');
        }
        setPageReady(true);
      });

    // Abort fetch when component unmounts
    return () => {
      api_config.ABORT_CONTROLLER.abort();
    };
  }, [triggerSubscriptionStatusRefresh]);

  const handlePayNowAction = () => {
    setCheckoutInProgress(true);
    let payload = {
      plan: selectedPackage.toLowerCase(),
      billing_cycle: isYearly?12:1,
      addons: summary.addons,
    };
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/create-checkout-session/';
    let apiController = new AbortController();
    const signal = apiController.signal;

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
        window.location.href = data.url;
      })
      .catch(function (err) {
        if (err.name === 'AbortError') {
          message.error('Requested aborted');
        } else {
          message.error('Error on processing request');
        }
        setCheckoutInProgress(false);
      });
  };

  const submitAppSumoCode = () => {

    if(codeRedeemInProgress) {
       return false;
    }

    if(!appSumoCode) {
      codeInputRef.current.focus();
      return false;
    }

    setCodeRedeemInProgress(true);

    let payload = {
      code: appSumoCode
    };
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/redeem-code/';
    let apiController = new AbortController();
    const signal = apiController.signal;

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        if(data.code === 200) {
          setTriggerSubscriptionStatusRefresh(!triggerSubscriptionStatusRefresh);
          notification.success({
            message: 'Code applied successfully',
            description: "We have applied your code successfully.",
          });
        }
        else {
          message.error(data.message);
        }
        setCodeRedeemInProgress(false);
      })
      .catch(function (err) {
        setCodeRedeemInProgress(false);
        if (err.name === 'AbortError') {
          message.error('Requested aborted');
        } else {
          message.error('Error on processing request');
        }
      });
  }

  const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0px 20px 0px;
  height: 220px;
`;

const CardContainer2 = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0px 20px 0px;
`;

const StyledPlanItemCard = styled(Card)`
  // max-width: 1000px;
  // min-width: 420px;
  // width: 100%;
  // margin: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  background: #fff;
  padding: 8px;

  .ant-card-body {
    padding: 5px;
  }
`;

const switchDisLabel = (checked) => {
  // "Voiceover", "Dubbing", "Writer"
  //alert(currentPlanType);
  if(checked) {
    if(currentPlanType === "Voiceover") {
      return "Yearly (77.5% off)"
    }
    if(currentPlanType === "Dubbing") {
      return "Yearly (60% off)"
    }
    if(currentPlanType === "Writer") {
      return "Yearly (60% off)"
    }
  }
  else {
    if(currentPlanType === "Voiceover") {
      return "Monthly (70% off)"
    }
    if(currentPlanType === "Dubbing") {
      return "Monthly (50% off)"
    }
    if(currentPlanType === "Writer") {
      return "Monthly (50% off)"
    }
  }
}

const get_plan_disc = () => {
  if(isYearly) {
    if(currentPlanType === "Voiceover") {
      return "77.5% off"
    }
    if(currentPlanType === "Dubbing") {
      return "60% off"
    }
    if(currentPlanType === "Writer") {
      return "60% off"
    }
  }
  else {
    if(currentPlanType === "Voiceover") {
      return "70% off"
    }
    if(currentPlanType === "Dubbing") {
      return "50% off"
    }
    if(currentPlanType === "Writer") {
      return "50% off"
    }
  }
}


const AllPlanComponent = () => {
    return (
        <>
        {pageReady && (
            <div className="pricing-component" style={{ padding: '0 30px' }}>
                
                {
                false && (!subscriptionInfo || !subscriptionInfo.status || subscriptionInfo.status.toLowerCase() === 'canceled') && 
                <StepsWrapper>
                    <Steps current={currentStep} size="small">
                    {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                    ))}
                    </Steps>
                </StepsWrapper>
                }            

                <div
                className="steps-content"
                >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    
                    {
                        //(!subscriptionInfo || !subscriptionInfo.status || subscriptionInfo.status.toLowerCase() === 'canceled') && currentStep < steps.length - 1
                        currentStep < steps.length - 1 && 
                        <>
                        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                        <span style={{ marginRight: '8px' }}></span>
                        <Switch checkedChildren={switchDisLabel(true)} unCheckedChildren={switchDisLabel(false)} checked={isYearly} onChange={handleSwitchChange} />
                        <span style={{ marginLeft: '8px' }}></span>
                        </div>
                        {
                        false && 
                        <p style={{color: '#107610', fontSize: '1.1rem', fontWeight: '600'}}>{!isYearly?'(Save 52% in yearly plan)': ''}</p>
                        }
                        </>
                    }
                    
                    <div
                    className='steps-content-inside'
                    >                  
                    {steps[currentStep].content}
                    {((selectedPackage && currentStep === steps.length - 1)) && (
                        <div className="payment-summary">
                        <h3>Payment Summary</h3>
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{flexGrow: '1', width: '450px', marginRight: '20px'}}>
                            <List.Item>
                                <List.Item.Meta
                                    title={<span style={{ color: 'black' }}>Package</span>}
                                    description={<span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1.2rem', fontWeight: '600' }}>{capitalize(summary.package)}{isYearly?' (Yearly)': ''}</span>}
                                />
                                {
                                    '$' + summary.package_price + ' per month'
                                }
                                
                            </List.Item>
                            {
                                <>
                                    {
                                    isYearly && 
                                    <List.Item>
                                        <List.Item.Meta
                                        description={<span style={{ color: 'rgba(0, 0, 0, 0.85)', opacity: '0.4', fontSize: '1.1rem', fontWeight: '600', textDecoration: 'line-through' }}>Original: ${summary.yearlyOriginal.toFixed(2)}</span>}
                                        />
                                    </List.Item>
                                    }
                                    {
                                    !isYearly && 
                                    <List.Item>
                                        <List.Item.Meta
                                        description={<span style={{ color: 'rgba(0, 0, 0, 0.85)', opacity: '0.4', fontSize: '1.1rem', fontWeight: '600', textDecoration: 'line-through' }}>Original: ${summary.monthlyOriginal.toFixed(2)}</span>}
                                        />
                                    </List.Item>
                                    }
                                </>
                            }
                            {
                                <List.Item>
                                    <List.Item.Meta
                                    description={
                                        (isYearly?<div style={{fontWeight: '900', color: 'green'}}>({get_plan_disc()})</div>:<div style={{fontWeight: '900', color: 'green'}}>({get_plan_disc()})</div>)
                                    }
                                    />
                                </List.Item>
                            }
                            <List.Item>
                                <List.Item.Meta
                                title={"You will pay" + " (" + (isYearly?"per year": "per month") + ")"}
                                //description={`$${summary.totalCost.toFixed(2)}`}
                                description={<span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1.4rem', fontWeight: '600' }}>${summary.totalCost.toFixed(2)}</span>}
                                />
                            </List.Item>
                            <p style={{opacity: '0.5', fontStyle: 'italic'}}>(You will be charged { isYearly? 'yearly': 'monthly' })</p>
                            {/* <List.Item className='alert alert-info'>
                                <List.Item.Meta
                                description={<span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '0.9rem' }}>To apply the promotion, use coupon code: <b>SPECIAL</b> during checkout</span>}
                                />
                            </List.Item> */}
                            </div>
                            <div>
                            <PaymentCommonInfo />
                            </div>
                        </div>
                        
                        </div>
                    )}
                    </div>
                    <div className="steps-action">
                    {currentStep > 0 && (
                        <Button
                        style={{ margin: '0 8px', width: '120px' }}
                        onClick={() => {setCurrentStep(currentStep - 1); resetPackage();}}
                        >
                        Previous
                        </Button>
                    )}
                    {false && currentStep < steps.length - 1 && (
                        <Button
                        style={{ margin: '0 8px', width: '120px' }}
                        disabled={!selectedPackage}
                        type="danger"
                        onClick={() => setCurrentStep(currentStep + 1)}
                        >
                        Next
                        </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button
                        style={{ margin: '0 8px', width: '120px' }}
                        type="primary"
                        onClick={() => {
                            if (!checkoutInProgress) handlePayNowAction();
                        }}
                        >
                        {checkoutInProgress && (
                            <Spin
                            indicator={antIcon}
                            style={{
                                color: 'white',
                                marginRight: '10px',
                                marginTop: '3px',
                            }}
                            />
                        )}
                        Pay now
                        </Button>
                    )}
                    </div>
                
                </div>
                </div>
            </div>
            )}
        </>
    )
}

  return (
    <>
      {!pageReady && (
        <div className="pricing-component" style={{ padding: '30px' }}>
          <span>
            <Spin />
          </span>
        </div>
      )}

      {
        showCurrentPlan && <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'start', marginTop: '20px'}}>
            {pageReady && appSumoPlan && appSumoPlan.plan_name && 
                <CardContainer style={{position: 'relative', marginRight: '5px'}}>
                    <span style={{position: 'absolute', top: '-10px', left: '0px', fontSize: '0.8rem', fontWeight: '600', zIndex: '99', color: '#6d6d6d'}}>YOUR PLAN</span>
                    <StyledPlanItemCard>
                    <h3 style={{fontSize: '1.4rem', marginTop: '5px', color: 'rgb(20, 20, 20)', marginBottom: '0rem', fontWeight: '900'}}>{appSumoPlan.plan_name} plan</h3>
                    <div className='next-billing-alert'
                      style={{ marginBottom: '20px', backgroundColor: '#d9f9d9', color: '#000', fontSize: '0.9rem', paddingBottom: '3px' }}>Next credit renew date: {new Date(appSumoPlan.credit_renew_date).toLocaleDateString()}</div>
                    <div style={{textAlign: 'left', fontSize: '0.9rem', marginTop: '30px'}}>- To upgrade, please click on AppSumo Plan tab and redeem more code.</div> 
                    <div style={{textAlign: 'left', fontSize: '0.9rem'}}>- To downgrade plan, please contact <a style={{color: '#fff', textDecoration: 'underline'}} href='mailto:support@unmixr.com'>support@unmixr.com</a></div>
                    </StyledPlanItemCard>                  
                </CardContainer>
            }
            {pageReady &&
                subscriptionInfo &&
                subscriptionInfo.status &&
                subscriptionInfo.status.toLowerCase() !== 'canceled' && (
                <CardContainer>
                    <StyledPlanItemCard>
                    <PlanDetails planDetails={subscriptionInfo} change_plan_click_handler={handle_change_plan} trigger_subscription_status={() => {
                        setTriggerSubscriptionStatusRefresh(!triggerSubscriptionStatusRefresh);
                    }} />
                    </StyledPlanItemCard>
                </CardContainer>
                )}
                {
                !(pageReady && appSumoPlan && appSumoPlan.plan_name) && !(pageReady &&
                    subscriptionInfo &&
                    subscriptionInfo.status &&
                    subscriptionInfo.status.toLowerCase() !== 'canceled') && 
                    <CardContainer2>
                    <StyledPlanItemCard>
                    <h3 style={{fontSize: '1.4rem', color: 'rgb(20, 20, 20)', fontWeight: '900'}}>No plan subscribed</h3>
                    </StyledPlanItemCard>
                    </CardContainer2>
                }
        </div>
      }

    {
        showTabs && <Tabs className='my-plan' defaultActiveKey={defaultTab || "current-plan"}>
                <TabPane tab="All plans" key="all-plans">
                <AllPlanComponent />
                </TabPane>
                <TabPane tab="AppSumo Plan" key="appsumo">
                <AppSumoPlanCardContainer>
                    <StyledAppSumoPlanItemCard>
                        <h3 style={{fontSize: '1.4rem', fontWeight: '900', color: '#4A90E2'}}>Redeem AppSumo code</h3>
                        <h3 style={{color: 'rgb(177, 177, 177)'}}>You can redeem maximum three codes</h3>
                        <Input ref={codeInputRef} value={appSumoCode} onInput={(e) => {setAppSumoCode(e.target.value)}} type='text' placeholder='Enter your appsumo code'/>
                        <div style={{marginTop: '5px', textAlign: 'right'}}>
                        <Button style={{fontWeight: '600'}} onClick={() => {submitAppSumoCode()}} className='unmixr-primary'>Redeem</Button>
                        </div>
                    </StyledAppSumoPlanItemCard>
                </AppSumoPlanCardContainer>
                </TabPane>
        </Tabs>
    }

    {!showTabs && <AllPlanComponent />}
          
    </>
  );
};

const AppSumoPlanCardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0px 10px 0px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
`;

const StyledAppSumoPlanItemCard = styled(Card)`
  max-width: 800px;
  min-width: 420px;
  margin: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
`;

export default PlansComponent;
