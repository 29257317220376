import React, { useEffect, useState } from 'react';
import { Alert, Button, message, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import { CheckCircleOutlined } from '@ant-design/icons';


const UpgradeButton = ({ onClick }) => { 
    const [triggerSubscriptionStatusRefresh, setTriggerSubscriptionStatusRefresh] = useState(false);
    const [subscriptionInfo, setSubscriptionInfo] = useState({});
    const [trialStatus, setTrialStatus] = useState(null);
    const [trialPlan, setTrialPlan] = useState(null);
    const [currentPlanName, setCurrentPlanName] = useState(null);
    const [appSumoPlan, setAppSumoPlan] = useState({});
    const [pageReady, setPageReady] = useState(false);
    const [isYearly, setIsYearly] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // Call API to create transcription
        let url = api_config.BASE_BACKEND_ENDPOINT + 'api/subscription-status/';
        let apiController = new AbortController();
        const signal = apiController.signal;
        let payload = {};

        UseAPI(signal, url, 'POST', payload)
        .then(function (data) {
            // handle data here
            //console.log(data);
            if (data.code === 200) {
                setSubscriptionInfo(data.plan);
                setTrialStatus(data.trial_status);
                setTrialPlan(data.trial_plan);
                setCurrentPlanName(data.plan_name);
                setAppSumoPlan(data.appsumo_plan);
                setIsYearly(data.plan && data.plan.billing_cycle === "year");
            } else if (data.code === 401) {
            //window.location.href = "/login";
            } else {
            message.error('Could not fetch subscription detail');
            }
            setPageReady(true);
        })
        .catch(function (err) {
            if (err.name === 'AbortError') {
            console.log('Fetch aborted');
            } else {
            message.error('Error while fetching subscription detail');
            }
            setPageReady(true);
        });

        // Abort fetch when component unmounts
        return () => {
        api_config.ABORT_CONTROLLER.abort();
        };
    }, [triggerSubscriptionStatusRefresh]);

  return (
    <>
        {
            pageReady && <>
            {appSumoPlan && !currentPlanName && 
                <Tooltip title={`You have subscribed to ${appSumoPlan.plan_name}`}>
                    <Button className='unmixr-primary6' style={{width: '100%', textTransform: 'capitalize'}} onClick={(e) => {navigate("/plan?popup=true")}}><CheckCircleOutlined  style={{ color: 'rgb(255 255 255)' }}/> {appSumoPlan.plan_name}</Button>
                </Tooltip>
            }
            {
                !appSumoPlan && currentPlanName && 
                <Tooltip title={`You have subscribed to ${currentPlanName}`}>
                        <Button className='unmixr-primary6' style={{width: '100%', textTransform: 'capitalize'}} onClick={(e) => {onClick(e);}}><CheckCircleOutlined  style={{ color: 'rgb(255 255 255)' }}/> {currentPlanName} ({subscriptionInfo.status})</Button>
                </Tooltip>
            }

            {
                appSumoPlan && currentPlanName && 
                <Tooltip title={`You have subscribed to multiple plan: ${currentPlanName} and ${appSumoPlan.plan_name}`}>
                    <Button className='unmixr-primary6' style={{width: '100%', textTransform: 'capitalize'}} onClick={(e) => {navigate("/plan")}}><CheckCircleOutlined  style={{ color: 'rgb(255 255 255)' }}/> {currentPlanName} ({subscriptionInfo.status})</Button>
                </Tooltip>                    
            }
            {
                !appSumoPlan && !currentPlanName && 
                <Tooltip title={`You do not have any active plan. Please upgrade to one.`}>
                        <Button className='unmixr-warning big-btn' style={{width: '100%', textTransform: 'capitalize'}} onClick={(e) => {onClick(e);}}>Upgrade to Plan</Button>
                </Tooltip>
            }
            </>
        }
        
    </>
  );
};

export default UpgradeButton;
