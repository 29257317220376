import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Collapse } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import api_config from 'lib/api/common';
import UseAPI from 'lib/api/UseAPI';

const { Panel } = Collapse;

// Styled Components
const TimerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 6px;
  margin: 0px;
  background: rgb(0, 121, 107);
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  width: 200px;

  @media (max-width: 768px) {
    left: 40px;
    border-radius: 8px 8px 0 0;
  }
`;


const TimerContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px;
`;

const TimerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: bold;
  }

  small {
    font-size: 12px;
  }
`;

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  color: #fff;

  .icon {
    margin-left: 5px;
  }
`;

const StyledCollapse = styled(Collapse)`
    background: rgb(0, 121, 107);
    color: #fff;

    .ant-collapse-item {
        background: rgb(0, 121, 107);
        color: #fff;

        .ant-collapse-header {
            background: rgb(0, 121, 107);
            color: #fff;
            padding: 0px 4px; /* Adjust padding as needed */
        }

        .ant-collapse-content {
            background: rgb(0, 121, 107);
            color: #fff;

            .ant-collapse-content-box {
                background: rgb(0, 121, 107);
                color: #fff;
                padding: 10px; /* Adjust padding as needed */
            }
        }
    }

    .ant-collapse-arrow,
    .ant-collapse-expand-icon {
        display: none;
    }
`;

// Countdown Timer Component
const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [expanded, setExpanded] = useState(false);
  const [trialStatus, setTrialStatus] = useState(null);
  const [currentPlanName, setCurrentPlanName] = useState(null);

  // Function to calculate the time left
  function calculateTimeLeft() {
    const difference = new Date(targetDate) - new Date();
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return { days, hours, minutes, seconds };
    }
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  // Update the timer every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const toggleExpanded = () => setExpanded(!expanded);

  const navigate2 = useNavigate();

  useEffect(() => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/subscription-status/';
    let apiController = new AbortController();
    const signal = apiController.signal;
    let payload = {};

    UseAPI(signal, url, 'POST', payload)
    .then(function (data) {
        if (data.code === 200) {
            setTrialStatus(data.trial_status);
            setCurrentPlanName(data.plan_name);
        }
    })
    .catch(function (err) {
        
    });

    return () => {
        apiController.abort();
    };
}, []);

  const header = (
    <ExpandButton onClick={toggleExpanded}>
      <span>
      <TimerContainer>
            <TimerBlock>
              <span>{String(timeLeft.days).padStart(2, '0')}</span>
              <small>Days</small>
            </TimerBlock>
            <TimerBlock>
              <span>{String(timeLeft.hours).padStart(2, '0')}</span>
              <small>Hours</small>
            </TimerBlock>
            <TimerBlock>
              <span>{String(timeLeft.minutes).padStart(2, '0')}</span>
              <small>Minutes</small>
            </TimerBlock>
            <TimerBlock>
              <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
              <small>Seconds</small>
            </TimerBlock>
          </TimerContainer>
      </span>
      {!expanded ? <UpOutlined className="icon" /> : <DownOutlined className="icon" />}
    </ExpandButton>
  );

  const descriptionHtml = `
    <p>Black Friday offer upto 80% lifetime discount on Voiceover monthly plans. And upto 84% lifetime discount in Voiceover yearly plans.</p>
    <p>50% lifetime discount on Dubbing and Writer monthly plans and 60% lifetime discount in Dubbing and Writer yearly plans.</p>
  `;

  return (
    <>
    {
        (!currentPlanName || (trialStatus && trialStatus.toUpperCase() === "TRIALING")) && <TimerWrapper>
            <StyledCollapse
            activeKey={expanded ? ['1'] : []}
            onChange={toggleExpanded}
            bordered={false}
            ghost
            >
            <Panel header={header} key="1">
                <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                <p style={{marginTop: '20px', textAlign: 'center'}}>
                    <Button style={{width: '100%'}} onClick={() => {navigate2('/plan?popup=true')}} className='unmixr-primary6'>Buy Now</Button>
                </p>
            </Panel>
            </StyledCollapse>
        </TimerWrapper>
    }
    </>
  );
};

export default CountdownTimer;
